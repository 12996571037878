(function () {
  'use strict';

  class CanvasZoom {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, resource, callback) {
      const vm = this;
      vm.resource = resource;

      vm.$mdDialog.show({
        controller: 'CanvasZoomCtrl',
        controllerAs: 'canvasZoom',
        templateUrl: 'components/canvas-zoom/canvas-zoom.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.resource;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:CanvasZoom
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('CanvasZoom', CanvasZoom);
}());
